




import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'

export const bus = new Vue()

@Component({})
export default class Chat extends Vue {
  private buttonAlreadyPositioned = false

  mounted (): void {
    router.afterEach((to, from) => {
      console.log('[Chat] router.afterEach ' + to.fullPath, { to, from })

      this.refreshChatButton()
      this.buttonAlreadyPositioned = false
    })

    bus.$on('PAGE_LOADED', () => {
      console.log('[Chat] bus.$on PAGE_LOADED')
      this.$store.dispatch('timer/resume')
      this.refreshChatButton()
      this.buttonAlreadyPositioned = false
    })
  }

  refreshChatButton (): void {
    if (!(window as unknown as any).lpTag) {
      return
    }
    (window as unknown as any).lpTag.newPage(window.location.href)
  }
}

import { ActionContext, ActionTree } from 'vuex'
import { TimerState } from '@/store/modules/timer/index'
import { ApplicationState } from '@/store'
import { Location } from 'vue-router'

export default {
  start (context, payload: { sourceLocation: Location, durationInSeconds: number }): void {
    // timer won't (re)start if it has already been started
    if (context.state.startedAt != null) return

    const timeoutHandle = setTimeout(() => context.commit('trackCompletedTimer', payload.sourceLocation), payload.durationInSeconds * 1000)
    context.commit('startInfo', { sourceLocation: payload.sourceLocation, timeoutHandle: timeoutHandle, startedAt: Date.now(), durationInSeconds: payload.durationInSeconds })

    updateElapsedTime(context)
  },
  clear (context): void {
    context.commit('startInfo', { sourceLocation: null, timeoutHandle: null, startedAt: null, durationInSeconds: null })
    context.commit('elapsedTimeInSeconds', null)
    context.commit('clearAlreadyCompletedTimers', [])
    console.log('timer called clear')
  },
  resume (context): void {
    updateElapsedTime(context)
  },
  stop (context): Promise<void> {
    return new Promise((resolve) => {
      context.commit('startInfo', { sourceLocation: null, timeoutHandle: null, startedAt: null, durationInSeconds: null })
      context.commit('elapsedTimeInSeconds', null)

      resolve()
    })
  },
  restart (context): void {
    const sourceLocation = context.state.sourceLocation
    const durationInSeconds = context.state.durationInSeconds

    context
      .dispatch('stop')
      .then(() => context.dispatch('start', { sourceLocation, durationInSeconds }))
  },
  disableTimerBar (context): void {
    context.commit('timerBarEnabled', false)
  },
  enableTimerBar (context): void {
    context.commit('timerBarEnabled', true)
  }
} as ActionTree<TimerState, ApplicationState>

function updateElapsedTime (context: ActionContext<TimerState, ApplicationState>): void {
  if (!context.state.startedAt) return
  if (context.getters.finished) return

  requestAnimationFrame(() => updateElapsedTime(context))

  const elapsedTime = (Date.now() - context.state.startedAt) / 1000
  context.commit('elapsedTimeInSeconds', elapsedTime)
}












import { Component, Vue } from 'vue-property-decorator'
@Component({})
export default class ChatButtonProxy extends Vue {
  $refs!: {
    fakeBtn: HTMLElement
  }

  private btnPos = {
    top: '0px'
  }

  private mounted (): void {
    const alignmentPoint = document.querySelector('.alignment-point')
    if (alignmentPoint) {
      this.btnPos.top = alignmentPoint.getBoundingClientRect().top + 'px'
      if (window.innerWidth > this.$data.mobileWidth) {
        this.$root.$on('timer-show', () => {
          this.btnPos.top = alignmentPoint.getBoundingClientRect().top + 46 + 'px'
        })
        this.$root.$on('timer-hide', () => {
          this.btnPos.top = alignmentPoint.getBoundingClientRect().top + 'px'
        })
      }
    }
  }

  private triggerEvent (): void {
    const a = setInterval(() => {
      const buttons = document.getElementsByClassName('LPMcontainer LPMoverlay')
      const button: HTMLButtonElement | any = buttons.length > 0 ? buttons[0] : null
      if (button) {
        button.click()
        clearInterval(a)
      }
    }, 10)
  }
}

import store from '@/store'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'splash',
    component: () => import(/* webpackChunkName: "splash" */ '../views/SplashScreen.vue')
  },
  {
    path: '/log-in',
    name: 'log in',
    component: () => import(/* webpackChunkName: "logIn" */ '../views/LogIn.vue')
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/pre-test',
    name: 'pre test',
    component: () => import(/* webpackChunkName: "preTest" */ '../views/PreTest/PreTest.vue'),
    children: [
      {
        path: 'video',
        name: 'pre test video',
        component: () => import(/* webpackChunkName: "Pre-test question video" */ '../views/PreTest/Video.vue'),
        meta: {
          advancement: 0,
          hideStepCount: true,
          middleware: 'auth'
        }
      },
      {
        path: '1',
        name: 'pre test 1',
        component: () => import(/* webpackChunkName: "Step_1" */ '../views/PreTest/Step1.vue'),
        meta: {
          advancement: 1,
          middleware: 'auth'
        }
      },
      {
        path: '2',
        name: 'pre test 2',
        component: () => import(/* webpackChunkName: "Step_2" */ '../views/PreTest/Step2.vue'),
        meta: {
          advancement: 2,
          middleware: 'auth'
        }
      },
      {
        path: '3',
        name: 'pre test 3',
        component: () => import(/* webpackChunkName: "Step_3" */ '../views/PreTest/Step3.vue'),
        meta: {
          advancement: 3,
          middleware: 'auth'
        }
      },
      {
        path: '4',
        name: 'pre test 4',
        component: () => import(/* webpackChunkName: "Step_4" */ '../views/PreTest/Step4.vue'),
        meta: {
          advancement: 4,
          middleware: 'auth'
        }
      }
    ]
  },
  {
    path: '/quidel',
    name: 'quidel',
    component: () => import(/* webpackChunkName: "quidle" */ '../views/Quidel/Quidel.vue'),
    children: [
      {
        path: '1',
        name: 'explanation 1',
        component: () => import(/* webpackChunkName: "Step_1" */ '../views/Quidel/Step1.vue'),
        meta: {
          middleware: 'auth'
        }
      },
      {
        path: '2/:name?',
        name: 'quidel 2',
        component: () => import(/* webpackChunkName: "Step_2" */ '../views/Quidel/step_2/Step2.vue'),
        meta: {
          middleware: 'auth'
        }
      },
      {
        path: '3',
        name: 'quidel 3',
        component: () => import(/* webpackChunkName: "Step_3" */ '../views/Quidel/Step3.vue'),
        meta: {
          middleware: 'auth'
        }
      },
      {
        path: '4/:name?',
        name: 'quidel 4',
        component: () => import(/* webpackChunkName: "Step_4" */ '../views/Quidel/step_4/Step4.vue'),
        meta: {
          middleware: 'auth'
        }
      },
      {
        path: '5',
        name: 'quidel 5',
        component: () => import(/* webpackChunkName: "Step_4" */ '../views/Quidel/Step5.vue'),
        meta: {
          middleware: 'auth'
        }
      },
      {
        path: 'result',
        name: 'result',
        component: () => import(/* webpackChunkName: "Result" */ '../views/Quidel/Result.vue'),
        meta: {
          middleware: 'auth'
        }
      }
    ]
  },
  {
    path: '/outcome/:type',
    name: 'outcome',
    component: () => import(/* webpackChunkName: "Outcome" */ '../views/Outcome/Outcome.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, __, next) => {
  if (to.meta.middleware) {
    const middleware = await import(`./middleware/${to.meta.middleware}`)
    if (middleware) {
      middleware.default(next, store)
    } else next()
  } else next()
})

export default router

import { ActionTree } from 'vuex'
import { WalkthroughState } from '@/store/modules/walkthrough/index'
import { ApplicationState } from '@/store'
import { TestResultOption } from '@/store/modules/walkthrough/types/types'
import { VaccinationState } from '@/store/modules/walkthrough/types/pre-test/vaccinationState'
import { SprayState } from '@/store/modules/walkthrough/types/pre-test/sprayState'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'
import statisticalDataService from '@/services/statistical-data-service'

export default {

  setSprayState (context, payload: SprayState) {
    context.commit('sprayState', payload)
  },

  setVaccinationState (context, payload: VaccinationState) {
    context.commit('vaccinationState', payload)
  },

  setSymptoms (context, payload: Symptoms) {
    context.commit('symptoms', payload)
  },

  setExposure (context, payload: Exposure) {
    context.commit('exposure', payload)
  },

  setSelectedOption (context, payload: TestResultOption) {
    context.commit('selectedOption', payload)
  },

  clearResults (context) {
    context.commit('sprayState', null)
    context.commit('vaccinationState', null)
    context.commit('symptoms', null)
    context.commit('exposure', null)
    context.commit('selectedOption', null)
  },

  async sendResult (context, payload: { testCompletionTime: Date, testValidityExpirationTime: Date }) {
    const userId = context.rootGetters['auth/userId']
    const organizationCode = context.rootGetters['auth/organizationCode']
    const sprayState = context.getters.sprayState
    const vaccinationState = context.getters.vaccinationState
    const symptoms = context.getters.symptoms
    const exposure = context.getters.exposure
    const selectedOption = context.getters.selectedOption

    await statisticalDataService.send(
      userId,
      organizationCode,
      payload.testCompletionTime,
      payload.testValidityExpirationTime,
      sprayState,
      vaccinationState,
      symptoms,
      exposure,
      selectedOption
    )
  },

  async completeTest (context) {
    const testCompletionTime = new Date()
    const testValidityExpirationTime = new Date(testCompletionTime.valueOf())
    testValidityExpirationTime.setTime(testValidityExpirationTime.getTime() + (context.state.test_validity_duration_in_hours * 60 * 60 * 1000))

    await context
      .dispatch('sendResult', { testCompletionTime, testValidityExpirationTime })
      .then(() => {
        context.commit('testCompletionTime', testCompletionTime)
        context.commit('testValidityExpirationTime', testValidityExpirationTime)
      })
  }

} as ActionTree<WalkthroughState, ApplicationState>

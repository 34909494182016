import { TestResultHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/testResultHighlight'
import { VaccinationStateHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/vaccinationStateHighlight'
import { SymptomsHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/symptomsHighlight'
import { ExposureHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/exposureHighlight'
import { OutcomeType } from '@/store/modules/walkthrough/types/outcomes/common/outcomeType'
import { ResultOverview } from '@/store/modules/walkthrough/types/outcomes/resultOverview/resultOverview'
import { VaccinationState } from '@/store/modules/walkthrough/types/pre-test/vaccinationState'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'
import { TestResult } from '@/store/modules/walkthrough/types/testResult'

export class OutcomeScenario {
  static readonly SCENARIO_1 = (vaccinationState: VaccinationState): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.NEGATIVE,
      new ResultOverview(
        'quidel.result.subtitle.standard.text',
        'quidel.result.description.negative.text',
        [
          TestResultHighlight.NEGATIVE,
          VaccinationStateHighlight.for(vaccinationState),
          SymptomsHighlight.NO_SYMPTOMS,
          ExposureHighlight.NO_EXPOSURE
        ]
      )
    )
  }

  static readonly SCENARIO_2 = (vaccinationState: VaccinationState): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.POSITIVE,
      new ResultOverview(
        'quidel.result.subtitle.standard.text',
        'quidel.result.description.positive.text',
        [
          TestResultHighlight.POSITIVE,
          VaccinationStateHighlight.for(vaccinationState),
          SymptomsHighlight.NO_SYMPTOMS,
          ExposureHighlight.NO_EXPOSURE
        ]
      )
    )
  }

  static readonly SCENARIO_3 = (vaccinationState: VaccinationState): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.POSITIVE,
      new ResultOverview(
        'quidel.result.subtitle.standard.text',
        'quidel.result.description.positive.text',
        [
          TestResultHighlight.NEGATIVE,
          VaccinationStateHighlight.for(vaccinationState),
          SymptomsHighlight.HAVE_SYMPTOMS,
          ExposureHighlight.NO_EXPOSURE
        ]
      )
    )
  }

  static readonly SCENARIO_4 = (): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.NEGATIVE,
      new ResultOverview(
        'quidel.result.subtitle.standard.text',
        'quidel.result.description.negative.text',
        [
          TestResultHighlight.NEGATIVE,
          VaccinationStateHighlight.FULLY_VACCINATED,
          SymptomsHighlight.NO_SYMPTOMS,
          ExposureHighlight.BEEN_EXPOSED
        ]
      )
    )
  }

  static readonly SCENARIO_5 = (): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.POSITIVE,
      new ResultOverview(
        'quidel.result.subtitle.standard.text',
        'quidel.result.description.positive.text',
        [
          TestResultHighlight.NEGATIVE,
          VaccinationStateHighlight.NOT_FULLY_VACCINATED,
          SymptomsHighlight.NO_SYMPTOMS,
          ExposureHighlight.BEEN_EXPOSED
        ]
      )
    )
  }

  static readonly SCENARIO_6 = (vaccinationState: VaccinationState): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.POSITIVE,
      new ResultOverview(
        'quidel.result.subtitle.standard.text',
        'quidel.result.description.positive.text',
        [
          TestResultHighlight.POSITIVE,
          VaccinationStateHighlight.for(vaccinationState),
          SymptomsHighlight.HAVE_SYMPTOMS,
          ExposureHighlight.BEEN_EXPOSED
        ]
      )
    )
  }

  static readonly SCENARIO_7 = (vaccinationState: VaccinationState): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.POSITIVE,
      new ResultOverview(
        'quidel.result.subtitle.standard.text',
        'quidel.result.description.positive.text',
        [
          TestResultHighlight.NEGATIVE,
          VaccinationStateHighlight.for(vaccinationState),
          SymptomsHighlight.HAVE_SYMPTOMS,
          ExposureHighlight.BEEN_EXPOSED
        ]
      )
    )
  }

  static readonly SCENARIO_8 = (): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.INCONCLUSIVE,
      new ResultOverview(
        'quidel.result.subtitle.inconclusive.text',
        '',
        [
          TestResultHighlight.INCONCLUSIVE
        ]
      )
    )
  }

  // eslint-disable-next-line no-useless-constructor
  private constructor (public readonly outcomeType: OutcomeType, public readonly resultOverview: ResultOverview) {
  }

  public static getMatchingScenario (vaccinationState: VaccinationState, symptoms: Symptoms, exposure: Exposure, testResult: TestResult): OutcomeScenario {
    if (testResult === null) {
      throw new Error('invalid test result')
    }
    if (testResult === TestResult.Inconclusive) {
      return OutcomeScenario.SCENARIO_8()
    } else if (testResult === TestResult.Positive && this.hasSymptoms(symptoms) && this.hasExposure(symptoms)) {
      return OutcomeScenario.SCENARIO_2(vaccinationState)
    } else if (testResult === TestResult.Positive && symptoms.any() && exposure.any()) {
      return OutcomeScenario.SCENARIO_6(vaccinationState)
    } else if (testResult === TestResult.Negative && this.hasSymptoms(symptoms) && this.hasExposure(symptoms)) {
      return OutcomeScenario.SCENARIO_1(vaccinationState)
    } else if (testResult === TestResult.Negative && symptoms.any() && this.hasExposure(symptoms)) {
      return OutcomeScenario.SCENARIO_3(vaccinationState)
    } else if (testResult === TestResult.Negative && vaccinationState === VaccinationState.FullyVaccinated && this.hasSymptoms(symptoms) && exposure.any()) {
      return OutcomeScenario.SCENARIO_4()
    } else if (testResult === TestResult.Negative && vaccinationState === VaccinationState.NotFullyVaccinated && this.hasSymptoms(symptoms) && exposure.any()) {
      return OutcomeScenario.SCENARIO_5()
    } else if (testResult === TestResult.Negative && symptoms.any() && exposure.any()) {
      return OutcomeScenario.SCENARIO_7(vaccinationState)
    } else {
      return OutcomeScenario.Custom(vaccinationState, symptoms, exposure, testResult)
    }
  }

  private static Custom (vaccinationState: VaccinationState, symptoms: Symptoms, exposure: Exposure, testResult: TestResult): OutcomeScenario {
    return new OutcomeScenario(
      OutcomeType.POSITIVE,
      new ResultOverview(
        'quidel.result.subtitle.standard.text',
        'quidel.result.description.positive.text',
        [
          TestResultHighlight.for(testResult),
          VaccinationStateHighlight.for(vaccinationState),
          SymptomsHighlight.for(symptoms),
          ExposureHighlight.for(exposure)
        ]
      )
    )
  }

  static hasSymptoms (symptoms: any):boolean {
    return !this.any(symptoms)
  }

  static any (symptoms: any):boolean {
    const existingSymptoms = ['fever', 'cough', 'breathingIssues', 'fatigue', 'musclesOrBodyAches', 'newOrUnusualHeadache',
      'newLossOfTasteOrSmell', 'soreThroat', 'newOrUnusualCongestionOrRunningNose', 'nauseaVomitOrOtherDigestiveSymptoms', 'diarrhea'
    ]
    return existingSymptoms.includes(symptoms)
  }

  static hasExposure (exposure: any):boolean {
    return !this.anyExposure(exposure)
  }

  static anyExposure (exposure: any):boolean {
    const existingExposure = ['exposedToSomeoneKnownToHaveCovid', 'exposedToSomeoneWithSymptoms']
    return existingExposure.includes(exposure)
  }
}

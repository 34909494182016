import axios from 'axios'
import { SprayState } from '@/store/modules/walkthrough/types/pre-test/sprayState'
import { VaccinationState } from '@/store/modules/walkthrough/types/pre-test/vaccinationState'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'
import { TestResultOption } from '@/store/modules/walkthrough/types/types'
import { TestResult } from '@/store/modules/walkthrough/types/testResult'
import authenticationService from '@/services/authentication-service'

export interface StatisticalDataService {
  send(userId: string, organizationCode: string, dateCompleted: Date, validUntil: Date, sprayState: SprayState, vaccinationState: VaccinationState, symptoms: Symptoms, exposure: Exposure, testResult: TestResultOption): Promise<void>
}

export class FirebaseStatisticalDataService implements StatisticalDataService {
  async send (userId: string, organizationCode: string, dateCompleted: Date, validUntil: Date, sprayState: SprayState, vaccinationState: VaccinationState, symptoms: Symptoms, exposure: Exposure, testResult: TestResultOption): Promise<void> {
    if (!process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      throw new Error('No base url specified for firebase functions')
    }

    try {
      const token = await authenticationService.getToken()
      const payload = buildPayload(userId, organizationCode, dateCompleted, validUntil, sprayState, vaccinationState, symptoms, exposure, testResult)
      console.log('[FirebaseStatisticalDataService] send', { payload, jsonPayload: JSON.stringify(payload) })
      const response = await axios
        .post<unknown>(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/organizations/${organizationCode}/submit`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json'
          }
        })

      console.log('[FirebaseStatisticalDataService] send got response', { response })
    } catch (error) {
      console.error('[FirebaseStatisticalDataService] send failed', error)
    }
  }
}

function mapToVaccinationStateStringValue (vaccinationState: VaccinationState): string {
  return vaccinationState === VaccinationState.FullyVaccinated
    ? 'FULLY_VACCINATED'
    : 'NOT_FULLY_VACCINATED'
}

function mapToSprayStateStringValue (sprayState: SprayState): string {
  return sprayState === SprayState.YesUsed
    ? 'YesUsed'
    : 'NotUsed'
}

function buildPayload (userId: string, organizationCode: string, dateCompleted: Date, validUntil: Date, sprayState:SprayState, vaccinationState: VaccinationState, symptoms: Symptoms, exposure: Exposure, testResult: TestResultOption) {
  return {
    organization: {
      organization_code: organizationCode
    },
    user: {
      uid: userId
    },
    test: {
      completed_at: dateCompleted,
      valid_until: validUntil,
      spray_state: mapToSprayStateStringValue(sprayState),
      vaccination_state: mapToVaccinationStateStringValue(vaccinationState),
      symptoms: {
        fever: symptoms.fever,
        cough: symptoms.cough,
        breathing_issues: symptoms.breathingIssues,
        fatigue: symptoms.fatigue,
        muscles_or_body_aches: symptoms.musclesOrBodyAches,
        new_or_unusual_headache: symptoms.newOrUnusualHeadache,
        new_loss_of_taste_or_smell: symptoms.newLossOfTasteOrSmell,
        sore_throat: symptoms.soreThroat,
        new_or_unusual_congestion_or_running_nose: symptoms.newOrUnusualCongestionOrRunningNose,
        nausea_vomit_or_other_digestive_symptoms: symptoms.nauseaVomitOrOtherDigestiveSymptoms,
        diarrhea: symptoms.diarrhea
      },
      exposure: {
        exposed_to_someone_known_to_have_covid: exposure.exposedToSomeoneKnownToHaveCovid,
        exposed_to_someone_with_symptoms: exposure.exposedToSomeoneWithSymptoms
      },
      test_result: mapToResultTypeStringValue(testResult.result)
    }
  }
}

function mapToResultTypeStringValue (result: TestResult): string {
  switch (result) {
    case TestResult.Positive:
      return 'POSITIVE'
    case TestResult.Negative:
      return 'NEGATIVE'
    case TestResult.Inconclusive:
      return 'INCONCLUSIVE'
  }
}

export const instance = new FirebaseStatisticalDataService()

export default instance

import firebaseService from '@/services/firebase-service'
import firebase from 'firebase'

export interface AuthenticationService {
  authenticate(): Promise<{ userId: string } | null>

  getToken(): Promise<string | null>
}

export class AuthenticationServiceImpl implements AuthenticationService {
  private authentication: firebase.auth.UserCredential | null = null

  async authenticate (): Promise<{ userId: string } | null> {
    const auth = await firebaseService.getAuth()
    const authentication = await auth.signInAnonymously()

    console.log('[AuthenticationServiceImpl] response', { authentication })

    if (!authentication || !authentication.user) {
      return null
    }

    this.authentication = authentication

    return { userId: authentication.user?.uid }
  }

  async getToken (): Promise<string | null> {
    return this.authentication?.user?.getIdToken() || null
  }
}

export const instance = new AuthenticationServiceImpl()

export default instance














import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import LineTimer from '@/components/common/LineTimer.vue'
import Chat from '@/components/common/Chat.vue'
import isOS from '@/helper/detectOs'

@Component({
  components: {
    Chat,
    Header,
    Footer,
    LineTimer
  }
})
export default class App extends Vue {
  private timerBarVisible = false

  private mounted (): void {
    window.scrollTo(0, 1)
    if (window.innerWidth < this.$data.mobileWidth && isOS()) window.scrollTo(0, 1)

    this
      .$store
      .watch(
        (state, getters) => getters['timer/timerBarVisible'],
        (timerBarVisible) => { this.timerBarVisible = timerBarVisible }
      )
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import actions from './actions'
import modules from './modules'
import mutations from '@/store/mutations'

Vue.use(Vuex)

export interface ApplicationState {
  initialized: boolean
}

export const initialState: ApplicationState = {
  initialized: false
}

export function getInitialState (): ApplicationState {
  return initialState
}

export default new Vuex.Store({
  state: getInitialState,
  mutations,
  actions,
  modules,
  plugins: [createPersistedState()]
})

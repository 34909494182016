import firebaseService from './firebase-service'

export declare type VideoURLs = string[]

export interface IVideoURLsDataSource {
  getVideoURLs(): Promise<VideoURLs>
}

export class RemoteConfigVideoURLsDataSource implements IVideoURLsDataSource {
  getVideoURLs (): Promise<VideoURLs> {
    return firebaseService.getRemoteConfig().then((remoteConfig) => {
      return remoteConfig.fetchAndActivate().then(() => {
        const configurationValue = remoteConfig.getString('BH_events_web_quidel_video')
        return JSON.parse(configurationValue)
      })
    })
  }
}

export const instance = new RemoteConfigVideoURLsDataSource()
export default instance








import { Component, Vue } from 'vue-property-decorator'
@Component({})
export default class LineTimer extends Vue {
  private timerStr = ''

  private unwatchRemainingTime: (() => void) | null = null

  private mounted (): void {
    this.unwatchRemainingTime = this
      .$store
      .watch(
        (state, getters) => getters['timer/timeRemainingInHumanReadableString'],
        (timeRemainingInHumanReadableFormat) => { this.timerStr = timeRemainingInHumanReadableFormat }
      )
  }

  private destroyed (): void {
    if (this.unwatchRemainingTime) this.unwatchRemainingTime()
  }

  private goTo (): void {
    const timerSourceLocation = this.$store.getters['timer/sourceLocation']
    this.$router.push(timerSourceLocation)
  }
}

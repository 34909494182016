/* eslint-disable camelcase */
import { Module } from 'vuex'
import { ApplicationState } from '@/store'
import { TestResultOption } from '@/store/modules/walkthrough/types/types'
import getters from '@/store/modules/walkthrough/getters'
import actions from '@/store/modules/walkthrough/actions'
import mutations from '@/store/modules/walkthrough/mutations'
import { SprayState } from './types/pre-test/sprayState'
import { VaccinationState } from '@/store/modules/walkthrough/types/pre-test/vaccinationState'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'

export interface WalkthroughState {
  spray_state: SprayState | null
  vaccination_state: VaccinationState | null;
  symptoms: Symptoms | null;
  exposure: Exposure | null;
  selected_option: TestResultOption | null;
  test_completion_time: Date | null;
  test_validity_expiration_time: Date | null;
  test_validity_duration_in_hours: number;
}

export const initialState = {
  spray_state: null,
  vaccination_state: null,
  symptoms: null,
  exposure: null,
  selected_option: null,
  test_completion_time: null,
  test_validity_expiration_time: null,
  test_validity_duration_in_hours: 12
} as WalkthroughState

export function getInitialState (): WalkthroughState {
  return initialState
}

export default {
  namespaced: true,
  state: getInitialState,
  getters,
  actions,
  mutations
} as Module<WalkthroughState, ApplicationState>

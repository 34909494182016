import firebaseService from './firebase-service'

export declare type Faq = any

export interface IFaqDataSource {
  getFaqData(): Promise<Faq>
}

export class RemoteConfigFaqDataSource implements IFaqDataSource {
  getFaqData (): Promise<Faq> {
    return firebaseService.getRemoteConfig().then((remoteConfig) => {
      return remoteConfig.fetchAndActivate().then(() => {
        const configurationValue = remoteConfig.getString('BH_faq_web')
        console.log('[RemoteConfigFaqDataSource] retrieve faqs', { configurationValue })
        return JSON.parse(configurationValue)
      })
    })
  }
}

export const instance = new RemoteConfigFaqDataSource()
export default instance

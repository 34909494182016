import firebase from 'firebase'

const lazyFirebase = import('firebase/app')
const lazyAuth = import('firebase/auth')
const lazyFunctions = import('firebase/functions')
const lazyRemoteConfig = import('firebase/remote-config')
export class FirebaseService {
  private _firebaseAppPromise?: Promise<firebase.app.App>

  public init (): Promise<any> {
    if (!this._firebaseAppPromise) {
      this._firebaseAppPromise = Promise
        .all([lazyFirebase, lazyAuth, lazyFunctions, lazyRemoteConfig])
        .then((resolvedPromises) => {
          const firebase: any = resolvedPromises[0].default
          const firebaseConfig = {
            apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
            authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.VUE_APP_FIREBASE_APP_ID,
            measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
          }

          return firebase.initializeApp(firebaseConfig)
        })
    }

    return this._firebaseAppPromise
  }

  public getFunctions (): Promise<any> {
    if (!this._firebaseAppPromise) return Promise.reject(new Error('FirebaseService instance have not been initialized'))

    // noinspection TypeScriptValidateJSTypes
    return this
      ._firebaseAppPromise
      .then((firebase) => firebase.functions())
  }

  public getRemoteConfig (): Promise<any> {
    if (!this._firebaseAppPromise) { this._firebaseAppPromise = this.init() }

    return this._firebaseAppPromise.then((firebase) => firebase.remoteConfig())
  }

  public getAuth (): Promise<firebase.auth.Auth> {
    if (!this._firebaseAppPromise) return Promise.reject(new Error('FirebaseService instance have not been initialized'))

    return this
      ._firebaseAppPromise
      .then((firebase) => firebase.auth())
  }
}

export const instance = new FirebaseService()
export default instance

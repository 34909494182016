import Vue from 'vue'
import App from './App.vue'
import './bootstrap-vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './v-mask'
import './mobile-detection'
import './assets/scss/app.scss'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueVideoPlayer from 'vue-video-player'
import ChatButtonProxy from './components/ChatButtonProxy.vue'
import 'video.js/dist/video-js.css'

const mixin = {
  data () {
    return {
      mobileWidth: 767
    }
  }
}

Vue.config.productionTip = false
Vue.use(VueVideoPlayer)
Vue.use(PerfectScrollbar)
Vue.component('ChatButtonProxy', ChatButtonProxy)
Vue.mixin(mixin)

store
  .dispatch('initialize')
  .then(() => {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  })
